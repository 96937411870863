import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FilterUiEffects } from './filter-ui.effects';
import { reducer } from './filter-ui.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([FilterUiEffects]),
    StoreModule.forFeature('filterUi', reducer),
  ],
  declarations: [],
})
export class FilterUiStateModule {}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get } from 'lodash-es';
import { merge, of } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';

import { BaseEffects } from '@locumsnest/core/src/lib/ngrx/effect';

import { FilterService } from '../filter.service';
import {
  InitializeFilterMessage,
  UpdateSelectedCategoryMessage,
  UpdateSelectedFilterGroupMessage,
  UpdateShowDepartmentSectionMessage,
} from './filter-ui.messages';
import {
  InitializeFilterContainerSignal,
  ShowHideDepartmentSectionSignal,
  UpdateSelectedCategorySignal,
  UpdateSelectedFilterGroupSignal,
} from './filter-ui.signals';

@Injectable()
export class FilterUiEffects extends BaseEffects {
  initializeFilterContainerSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeFilterContainerSignal>(InitializeFilterContainerSignal.TYPE),
      mergeMap(() => merge(this.filterService.loadFilters(), of(new InitializeFilterMessage({}))))
    )
  );

  showHideDepartmentSectionSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShowHideDepartmentSectionSignal>(ShowHideDepartmentSectionSignal.TYPE),
      switchMap(() =>
        this.filterService.getSelectedFilterGroups().pipe(
          first(),
          map(
            (filters) =>
              new UpdateShowDepartmentSectionMessage({
                selectedCategoryId: get(
                  filters.filterCategories.find(({ selected }) => selected),
                  'id',
                  null
                ),
              })
          )
        )
      )
    )
  );

  updateSelectedFilterGroupSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSelectedFilterGroupSignal>(UpdateSelectedFilterGroupSignal.TYPE),
      map(
        (action) =>
          new UpdateSelectedFilterGroupMessage({
            selectedFilterGroup: action.payload.selectedFilterGroup,
          })
      )
    )
  );

  updateSelectedCategorySignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSelectedCategorySignal>(UpdateSelectedCategorySignal.TYPE),
      map(
        (action) =>
          new UpdateSelectedCategoryMessage({
            selectedCategoryId: action.payload.selectedCategoryId,
          })
      )
    )
  );

  constructor(protected actions$: Actions, private filterService: FilterService) {
    super();
  }
}
